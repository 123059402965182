/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import Layout from "../../layouts/default"
import { jsx, useColorMode } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Divider from "../../components/divider"
import FloatContainer from "../../components/float-container"
import Heading from "../../components/heading"
import HyperCardMini from "../../components/hyper-card-mini"
import Image from "../../components/image"
import Inline from "../../components/inline"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

import logoLiLand from "../../images/partner-logos/liland-bawue.svg"
import logoLiLandWhite from "../../images/partner-logos/liland-bawue-white.svg"
import Constrain from "../../components/constrain"

const NeuigkeitenHoelderlinLiebt = () => {
  const [colorMode] = useColorMode()

  const data = useStaticQuery(graphql`
    query NeuigkeitenHoelderlinLiebt {
      titel: file(relativePath: { eq: "startseite/hoelderlin-liebt.jpg" }) {
        ...mediumImage
      }
      podcast: file(relativePath: { eq: "startseite/podcast-inklusiv.png" }) {
        ...smallImage
      }
      leichteSprache: file(
        relativePath: { eq: "startseite/leichte-sprache.png" }
      ) {
        ...smallImage
      }
      ministerium: file(
        relativePath: {
          eq: "partner-logos/ministerium-soziales-integration.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 200, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <Layout backdrop="aussen">
      <Seo
        title="Sonderausstellung ›Hölderlin liebt...‹"
        description="Friedrich Hölderlin liebt die Wörter. Die schwierigen ganz besonders. Seine Gedichte sind voll von ungewöhnlichen Wörtern. Das macht sie besonders, aber auch schwer zu verstehen. Mit der aktuellen Sonderausstellung wird sich dies nun ändern."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Sonderausstellung ›Hölderlin liebt...‹",
              link: "/neuigkeiten/hoelderlin-liebt",
            },
          ]}
        />
        <Stack space={6}>
          <PageTitle>Hölderlin liebt...</PageTitle>
          <Heading as="h2" level={4}>
            Eine Sonderausstellung in Einfacher Sprache und Gebärdensprache
          </Heading>
          <Heading as="h3" level={5} color="primary">
            Vom 9. Mai 2021 bis 14. Februar 2022
          </Heading>
        </Stack>

        <Box>
          <FloatContainer width="40%" align="right">
            <Image
              image={data.titel.childImageSharp.gatsbyImageData}
              alt="Ein buntes Porträt von Friedrich Hölderlin mit geometrischen Formen"
              caption="Illustration: Jette von Bodecker"
              attribution="Jette von Bodecker"
            />
          </FloatContainer>
          <Paragraph dropcap={true} mb={[6, 12]}>
            Der Dichter Friedrich Hölderlin liebt die Wörter. Die schwierigen
            ganz besonders. Seine Gedichte sind voll von ungewöhnlichen Wörtern.
            Das macht sie besonders, aber auch schwer zu verstehen. Manche sagen
            sogar: Es sind die schwierigsten Gedichte in deutscher Sprache. Die
            Gedichte von Hölderlin sind das Gegenteil von einfach.
          </Paragraph>
          <Paragraph>
            Mit einer Sonderausstellung über Friedrich Hölderlin in Einfacher
            Sprache und Deutscher Gebärdensprache wird sich dies nun ändern. Ein
            Bällebad mit Hölderlins Lieblingswörtern, eine Station mit Düften
            von Pflanzen und Blumen aus seinen Gedichten, ein von der
            Lebenshilfe Tübingen gestaltetes Magnet-Bilder-Spiel und vieles mehr
            erwartet die Besucher*innen.
          </Paragraph>
        </Box>

        <Paragraph>
          Die Ausstellung wurde in partizipativer Zusammenarbeit mit der
          Bruderhaus-Diakonie, der Lebenshilfe, der Kirnbachschule und dem Büro
          für Leichte Sprache in Tübingen entwickelt. Die Übersetzungen in
          Gebärdensprache entstanden unter anderem in zwei inklusiven Workshops
          mit Menschen mit und ohne Beeinträchtigungen und mit einem Team aus
          gehörlosen und hörenden Gebärdensprach-Dolmetscherinnen.
        </Paragraph>

        <HyperCardMini
          headingElement="h2"
          title="Podcast zur Ausstellung"
          description="Ein Rhetorik-Seminar der Uni Tübingen hat die Entstehung unserer aktuellen Ausstellung zu Hölderlin in Einfacher Sprache und Gebärdensprache ein Stück begleitet und Interviews mit vier beteiligten Personen geführt. Im Podcast schildern sie ihre Beweggründe für die Mitarbeit an der Ausstellung, geben Einblick in ihre Arbeitsprozesse und berichten von dem, was sie antreibt."
          image={data.podcast}
          to="/neuigkeiten/hoelderlin-inklusiv"
          cta="Jetzt anhören"
        />
        <HyperCardMini
          headingElement="h2"
          title="Weitere Angebote in Leichter Sprache"
          description={
            <span>
              Hier erfahren Sie mehr über das Leben von Friedrich Hölderlin und
              über seine Gedichte. Sie sehen Bilder und Ausstellungs∙stücke aus
              unserem Museum. Sie können ein Papier mit Hölderlins Handschrift
              erforschen. Und Sie erfahren, was man in unserem Museum machen
              kann.
            </span>
          }
          image={data.leichteSprache}
          to="/leichte-sprache"
          cta="Mehr lesen"
        />

        <Divider size={4} />
        <Constrain align="left">
          <Stack>
            <Paragraph size={3}>
              Das Projekt ›Hölderlin in Einfacher Sprache‹ wird unterstützt
              durch das Ministerium für Soziales und Integration
              Baden-Württemberg. Die Übersetzungen in Deutsche Gebärdensprache
              wurden gefördert durch die Arbeitsstelle für literarische Museen,
              Archive und Gedenkstätten in Baden-Württemberg.
            </Paragraph>
            <Inline alignY="center" space={12}>
              <Box sx={{ width: 48 }}>
                <Image
                  image={data.ministerium.childImageSharp.gatsbyImageData}
                  alt="Logo Ministerium für Soziales und Integration Baden-Württemberg"
                />
              </Box>
              <img
                src={colorMode === "light" ? logoLiLand : logoLiLandWhite}
                alt="Logo Literaturland Baden-Württemberg"
                width="82"
                height="92"
                sx={{ width: "auto", height: 24 }}
              />
            </Inline>
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default NeuigkeitenHoelderlinLiebt
